<template>
	<div>
		<input
			:id="safeName"
			type="checkbox"
			:checked="modelValue"
			:disabled="disabled"
			@input="$emit('update:modelValue', $event.target.checked)"
		>

		<label :for="safeName">
			{{ name }}<span v-if="required">*</span>
		</label>
	</div>
</template>

<script>
export default {
	name: "Checkbox",

	props: {
		name: {
			type: String,
			required: true
		},

		modelValue: {
			type: Boolean,
			required: false,
			default: false
		},

		required: {
			type: Boolean,
			required: false,
			default: false
		},

		disabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	emits: ["update:modelValue"],

	computed: {
		safeName () {
			return this.name.toLowerCase().replace(/[^\w]/gi, "");
		}
	}
};
</script>

<style scoped>
input {
	margin-bottom: 10px;
	box-sizing: border-box;
	padding: 10px 0;
	font-size: 12pt;
	transform: scale(0.75);
}

label {
	color: #999;
	font-size: small;
	vertical-align: middle;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
</style>
