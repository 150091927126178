<template>
	<div>
		<transition name="fade">
			<Message v-if="error" :message="error" type="error" />
		</transition>

		<transition name="fade">
			<Message v-if="status !== 0" :message="statusMessage" type="info" />
		</transition>

		<Message v-if="checkingSession" message="Checking Session Status" type="info" />

		<div v-else>
			<transition name="fadeInput">
				<InputField
					v-if="homeserverType === 'all'"
					v-model="homeserver"
					name="Homeserver"
					placeholder="https://matrix.org"
					:disabled="!!status"
					@submit="signIn"
				/>

				<DropDown
					v-else-if="homeserverType === 'dropdown' || showOtherHomeservers"
					v-model="homeserver"
					:options="allowedHomeservers"
					name="Homeserver"
					:disabled="!!status"
				/>
			</transition>

			<InputField
				v-model="user"
				:name="usernameFieldTitle || 'Matrix Username'"
				placeholder="username"
				:disabled="!!status"
				@submit="signIn"
			/>

			<Checkbox
				v-if="hasOtherHomeserverOptions"
				v-model="showOtherHomeservers"
				name="Show hidden homeservers?"
				:disabled="!!status"
			/>

			<InputField
				v-model="password"
				name="Password"
				type="password"
				:disabled="!!status"
				@submit="signIn"
			/>

			<SubmitButton :disabled="!!status" @click="signIn" />
		</div>
	</div>
</template>

<script>
import store from "@/store";
import api from "@/api";

import { matrix, requireConsent, meta } from "../../configuration/config-public-merged.js";

import InputField	from "@/components/InputField.vue";
import DropDown	from "@/components/DropDown.vue";
import Checkbox	from "@/components/Checkbox.vue";
import SubmitButton	from "@/components/SubmitButton.vue";
import Message	from "@/components/Message.vue";

const allWhitelistedHomeservers = (() => {
	const servers = [...matrix.whitelist.homeservers];

	// Get all the homeservers of other whitelisted accounts.
	try {
		for (const account of matrix.whitelist.accounts) {
			const homeserver = `https://${account.split(":")[1]}`;

			if (servers.includes(homeserver))
				continue;

			servers.push(homeserver);
		}
	} catch (e) {
		// Don't really want this to blow up with it is misconfigured.
		console.error(e);
	}

	return servers;
})();

export default {
	name: "Login",

	components: {
		InputField,
		SubmitButton,
		DropDown,
		Checkbox,
		Message
	},

	data () {
		return {
			usernameFieldTitle: meta["username-field-title"],
			homeserver: "https://matrix.org",
			user: "",
			password: "",
			status: 0,
			error: undefined,
			showOtherHomeservers: false,
			checkingSession: true
		};
	},

	computed: {
		statusMessage () {
			switch (this.status) {
				case 1:
					return "Signing into Matrix";
				case 2:
					return "Obtaining ID Token";
				case 3:
					return "Logging into Organic Design";
				case 4:
					return "Validating Login";
				case 5:
					return "Success!";
				default:
					return "";
			}
		},

		strippedHomeserverUrl () {
			return this.homeserver
				.replace("https://", "")
				.replace("http://", "");
		},

		allowedHomeservers () {
			return allWhitelistedHomeservers;
		},

		homeserverType () {
			if (matrix.whitelist.all)
				return "all";

			if (matrix.whitelist.homeservers.length == 1)
				return "static";

			return "dropdown";
		},

		matrixUsername () {
			if (/^@.+\:.+$/.test(this.user))
				return this.user.toLowerCase();

			if (/^@.+$/.test(this.user))
				return `${this.user}:${this.strippedHomeserverUrl}`.toLowerCase();

			return `@${this.user}:${this.strippedHomeserverUrl}`.toLowerCase();
		},

		hasOtherHomeserverOptions () {
			return this.homeserverType === "static" &&
				this.allowedHomeservers.length > matrix.whitelist.homeservers.length;
		}
	},

	watch: {
		showOtherHomeservers (newVal) {
			// Reset the homeserver back to it's default
			if (!newVal)
				this.homeserver = this.allowedHomeservers[0];
		}
	},

	async beforeMount () {
		this.homeserver = this.allowedHomeservers[0];

		this.checkState();
	},

	methods: {
		async checkState () {
			this.checkingSession = true && this.status === 0;

			await store.state.update();

			const route = store.state.getRoute();

			// We need a session before login so give self session.
			if (!store.state.status) {
				const thisSite = window.location.toString().match(/http[s]*\:\/\/[^/]+/)[0];
				const url = `${thisSite}/update`;

				let redirectionUrl = "/auth";

				redirectionUrl += "?client_id=self";
				redirectionUrl += "&response_type=code";
				redirectionUrl += "&scope=openid";
				redirectionUrl += `&redirect_uri=${url}`;

				window.location.href = redirectionUrl;
			}

			if (route === "/login")
				return this.checkingSession = false;

			// attempt auto consent if possible
			try {
				if (route === "/consent" && !requireConsent)
					return window.location.href = await api.consent();
			} catch (e) {
				// The next part can handle this case
			}

			return this.$router.push(route);
		},

		async signIn () {
			this.error = undefined;

			try {
				this.status++;

				const fullServerUrl = this.homeserver.startsWith("http") ?
					this.homeserver : `https://${this.homeserver}`;

				await api.matrixLogin(
					this.matrixUsername,
					this.password,
					fullServerUrl
				);

				this.status++;

				const token = await api.getOpenIdToken();

				this.status++;

				// Attempt to get the sso server to validate us.
				const redirect = await api.login(this.matrixUsername, token);

				this.status++;

				if (redirect === "/noaccess")
					return this.$router.push(redirect);

				// Get the new interactive session.
				await api.updateRedirectedSession(redirect);

				this.status++;

				this.checkState();
			} catch (e) {
				this.status = 0;

				console.error(e);

				this.error = e.message ? e.message : e;
			}
		}
	}
};
</script>
