<template>
	<button :class="type" :disabled="disabled">
		<slot>Submit</slot>
	</button>
</template>

<script>
export default {
	name: "SubmitButton",

	props: {
		type: {
			type: String,
			required: false,
			default: ""
		},

		disabled: {
			type: Boolean,
			required: false,
			default: false
		}
	}
};
</script>

<style scoped>
button {
	width: 100%;
	padding: 10px;
	cursor: pointer;
	border: none;
	background-color: #d9d9d9;
	color: black;
	border-radius: 3px;
	margin: 5px 0;
	font-size: 12pt;
}

button:disabled {
	cursor: default;
	background-color: #d9d9d9;
	color: #888;
}

button:enabled:hover {
	background-color: #e6e6e6;
}

button.important {
	background-color: #f21c0d;
	color: white;
}

button.important:enabled:hover {
	background-color: #f5493d;
}
</style>
